import React from 'react';
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import p13cencerroAudio from "../audio/p13cencerroAudio.m4a"
import p13VO from "../audio/p13VO.m4a"
import Footer from "../components/Footer"

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$|.png/));

class Page13 extends React.Component {
        constructor(props) {
            super(props)
            this.state = {
                active: false,
                cencerro: images["p13_cencerro.png"],
            }
        }
        playAudio = (x) => {
            const audioEl = document.getElementsByClassName("audio-element")[x]
            audioEl.play()
        }
        handleCencerroClick = () => {
        this.setState({ active: !this.state.active }, () => {
          if(this.state.active){
        setTimeout( () => {this.setState({cencerro: images["resetBotonTrans.png"]}); this.playAudio(1)}, 1000 );

      }
      else { this.setState({cencerro: images["p13_cencerro.png"]}); }
    })

}
        render() {
       return (
      <div className="main-container">
      <Link to="/Page12">
        <div className="page-turner">
          <img style={{transform: "scaleX(-1)", opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
        <div className="centered-container">
        <div className="backContainer004">
        <StaticQuery
          query={graphql`
            query {
              imageOne: file(relativePath: { eq: "p13_back.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
    render={data => (
      <Img fluid={data.imageOne.childImageSharp.fluid} />
    )}
  />
        </div>
        <div className="p13Cuentamelo"  onClick={() => this.playAudio(0)} onKeyDown={() => this.playAudio(0)} role="button" tabIndex={0}>
            <img className="hw" src={images["botonCuentamelo.png"]} alt="" />
        </div>
        <div className={"p13Looney" + (this.state.active ? " active" : "")} >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p13_looney.png"]} alt="" />
        </div>
        <div className={"_idContainer193" + (this.state.active ? " active" : "")} >
            <div className={"_idContainer191" + (this.state.active ? " active" : "")} >
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p13_cencerroDing.png"]} alt="" />
            </div>
            <div className="_idContainer192">
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p13_cencerroCampana.png"]} alt="" />
            </div>
        </div>
        <div className="_idContainer194">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p13_becerroCollar.png"]} alt="" />
        </div>
        <div className={"_idContainer198" + (this.state.active ? " active" : "")} >
            <div className="_idContainer195">
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p13_becerroOrejaL.png"]} alt="" />
            </div>
            <div className="_idContainer196">
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p13_bacerroCabeza.png"]} alt="" />
            </div>
            <div className="_idContainer197">
                <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p13_becerroOrejaR.png"]} alt="" />
            </div>
        </div>
        <div className={"_idContainer199 Basic-Text-Frame" + (this.state.active ? " active" : "")} >
            <span style={{textShadow: "2px 2px 4px black"}}>Y así el becerro se <span>unió con su cencerro</span> al grupo de animales<span> de El Gran Llano.</span></span>
        </div>
        <div className={"finLetras Basic-Text-Frame" + (this.state.active ? " active" : "")} >            <span>Fin</span>
        </div>
        <div className="_idContainer200">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4269.png"]} alt="" />
        </div>
        <div className="_idContainer201">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4270.png"]} alt="" />
        </div>
        <div className={"_idContainer202" + (this.state.active ? " active" : "")} onClick={() => {this.handleCencerroClick()}} onKeyDown={() => {this.handleCencerroClick()}} role="button" tabIndex={0}>
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={this.state.cencerro} alt="" />
        </div>
        <audio className="audio-element">
            <source src={p13VO}></source>
        </audio>
        <audio className="audio-element">
            <source src={p13cencerroAudio}></source>
        </audio>

    </div>
    <Footer />
    </div>
    )
  }
  }

export default Page13;
